export const API_END_POINT = process.env.NEXT_PUBLIC_API_END_POINT;
export const APP_SUPPORT_MAIL = process.env.NEXT_PUBLIC_SUPPORT_MAIL;
export const PLAY_STORE_URL = process.env.NEXT_PUBLIC_PLAY_STORE_URL;

export const API_END_POINT_V2 = API_END_POINT + "/v2";
export const API_END_POINT_V2_1 = API_END_POINT + "/v2.1";
export const API_END_POINT_V2_2 = API_END_POINT + "/v2.2";

export const ENV =
  process.env.NEXT_PUBLIC_ENV === "development" ? "development" : "production";

export const isProduction = ENV === "production";

export const isDev = ENV === "development";

export const isLocalhost = Boolean(
  typeof window !== "undefined" &&
    (window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      //for local environment
      window.location.hostname.startsWith("192.168") ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ))
);

export const REFFERAL_PAGE = process.env.NEXT_PUBLIC_REFERRAL_PAGE_LINK;
